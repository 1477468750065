import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Department";

export const getDepartmentDetailsUrl = (departmentId) =>
	`${baseUrl}/${departmentId}`;
export const getDepartmentsUrl = (studentTypeId, facultyId, isApplication) =>
	`${baseUrl}/${studentTypeId}${
		facultyId ? `/${facultyId}` : ""
	}/departments${isApplication ? `?application=${isApplication}` : ""}`;
export const getAllDepartmentsUrl = () => `${baseUrl}/all-departments`;
export const getDepartmentOptionUrl = ({ departmentId, studentTypeId }) =>
	`${baseUrl}${
		studentTypeId ? `/${studentTypeId}` : ""
	}/${departmentId}/departmentoptions`;
export const getApplicationDepartmentsUrl = (applicationTypeId, facultyId) =>
	`${baseUrl}/${applicationTypeId}${
		facultyId ? `/${facultyId}` : ""
	}/application-departments`;
export const getAllHODsUrl = (filter) =>
	`${baseUrl}/hods?${generateUrlParams(filter)}`;
export const assignHODToDepartmentUrl = () =>
	`${baseUrl}/assign-hod-to-department`;
export const jupebCourseOptionsUrl = () => `${baseUrl}/jupeb-options`;
export const jupebCourseOptionsSubjectUrl = (jupebOptionsId) =>
	`${baseUrl}/${jupebOptionsId}/jupeb-options-subjects`;
export const getLecturerDepartmentsUrl = (filter) =>
	`${baseUrl}/departments-for-result-approval?${generateUrlParams(filter)}`;
export const getDepartmentJupebOptionsUrl = () => `${baseUrl}/jupeb-options`;
export const getDepartmentJupebOptionsSubjects = (jupebOptionsId) =>
	`${baseUrl}/${jupebOptionsId}/jupeb-options-subjects`;
export const getAllDepartmentActiveSessionsUrl = (filter) =>
	`${baseUrl}/get-all-depts-active-sessions?${generateUrlParams(filter)}`;
export const rollbackDepartmentActiveSessionUrl = () =>
	`${baseUrl}/rollback-active-session`;
export const setDepartmentActiveSessionUrl = () =>
	`${baseUrl}/set-active-session`;
export const getLevelCourseAdvisersUrl = (filter) =>
	`${baseUrl}/get-department-level-course-advisers?${generateUrlParams(
		filter
	)}`;
export const assignLevelCourseAdvisersUrl = () =>
	`${baseUrl}/assign-level-course-adviser`;

export const canStudentUpdateDepratmentOptionUrl = () => 
	`${baseUrl}/can-student-update-department-option`;